import { isEmpty } from "lodash";
import { decorate, observable, runInAction } from "mobx";
import {
  activeCamapaignFeilds,
  getContactInfo,
  hubspotFieldList,
  importACContacts,
  importHubspotContacts,
  importZohoContacts,
  saveActiveCampToken,
  activeCamapaignFilter,
  saveGHLToken,
  saveHubspotToken,
  saveZohoToken,
  searchContact,
  sendInvoice,
  zohoFields
} from "service/integration";

class ObservableIntegrationStore {
  contact = undefined;
  response = undefined;
  invoiceLink = undefined;
  zohoFieldList = {
    type: "",
    list: []
  };
  hubspotFieldList = [];
  activeCampFields = [];
  activeCampFilter = {};
  groupName = undefined;

  importContactFormProps = undefined;

  setProps = data => {
    runInAction(() => {
      this.importContactFormProps = data ? data : undefined;
    });
  };

  setData = (field, data) => {
    runInAction(() => {
      this[field] = data;
    });
  };

  // SQURE-SPACE

  setInvoiceLink = link => {
    runInAction(() => {
      this.invoiceLink = link ? JSON.parse(JSON.stringify(link)) : undefined;
    });
  };

  searchContact = async mobile => {
    const response = await searchContact(mobile);
    try {
      runInAction(() => {
        this.response = response;
        this.contact = response?.data ? response?.data : undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  getContactInfo = async mobile => {
    const response = await getContactInfo(mobile);
    try {
      runInAction(() => {
        this.response = response;
        this.contact = response?.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  sendInvoice = async data => {
    const response = await sendInvoice(data);
    try {
      runInAction(() => {
        this.response = response;
        if (response?.data?.invoice_url) {
          this.invoiceLink = response?.data?.invoice_url;
        } else {
          this.invoiceLink = undefined;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // OAuth flow
  handleConnect(app, callBack, sendData) {
    let url = "";
    switch (app) {
      case "hubspot":
        url = `https://app.hubspot.com/oauth/authorize?client_id=${process.env.HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.HUBSPOT_REDIRECT_URI}&scope=${process.env.HUBSPOT_SCOPE}`;
        break;
      case "zoho":
        url = `https://${sendData?.accountsURL}/oauth/v2/auth?redirect_uri=${process.env.ZOHO_REDIRECT_URI}&scope=${process.env.ZOHO_SCOPE}&client_id=${process.env.ZOHO_CLIENT_ID}&response_type=code&access_type=offline`;
        break;
      case "ghl":
        url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${process.env.GHL_REDIRECT_URI}&client_id=${process.env.GHL_CLIENT_ID}&scope=${process.env.GHL_SCOPE}`;
        break;
      case "ac":
        url = `${window.origin}/auth/connect`;
        break;
    }

    sessionStorage.setItem("app", app);

    if (sendData) {
      sessionStorage.setItem("params", JSON.stringify(sendData));
    }
    const channel = new BroadcastChannel("window-communication");
    var authWindow = window.open(url, "authWindow", "with=500,height=auto");

    if (window?.focus) {
      authWindow?.focus();
    }
    window.addEventListener("message", function(event) {
      if (event.data === "authSuccess" || event.data?.ghl) {
        callBack(app, event.data);
        authWindow.close();
        channel.close();
      }
    });
    channel.onmessage = event => {
      if (event.data === "authSuccess") {
        if (authWindow) {
          callBack(app, event.data);
          authWindow.close();
          channel.close();
        }
      }
    };
  }

  importContacts = async (app, data) => {
    let response = undefined;

    switch (app) {
      case "hubspot":
        response = await importHubspotContacts(data);
        break;
      case "zoho":
        response = await importZohoContacts(data);
        break;
      case "ac":
        response = await importACContacts(data);
        break;
    }

    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // HUBSPOT

  saveHubspotToken = async data => {
    const response = await saveHubspotToken(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  hubspotFields = async data => {
    const response = !isEmpty(this.hubspotFieldList) ? { data: this.hubspotFieldList } : await hubspotFieldList(data);
    try {
      runInAction(() => {
        this.hubspotFieldList = response?.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.hubspotFieldList;
  };

  // ZOHO

  zohoFields = async type => {
    const response = this.zohoFieldList.list.length > 0 && type === this.zohoFieldList.type ? { data: this.zohoFieldList.list } : await zohoFields(type);

    this.zohoFieldList.type = type;

    try {
      runInAction(() => {
        this.zohoFieldList.list = response?.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.zohoFieldList.list;
  };

  saveZohoToken = async data => {
    const response = await saveZohoToken(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // GHL

  saveGHLToken = async data => {
    const response = await saveGHLToken(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // ACTIVE CAMPAIGN

  saveActiveCampToken = async data => {
    const response = await saveActiveCampToken(data);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  activeCampFetchFilter = async data => {
    const response = Object.entries(this.activeCampFilter).length > 0 ? { data: this.activeCampFilter } : await activeCamapaignFilter(data);
    try {
      runInAction(() => {
        this.activeCampFilter = response?.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.activeCampFilter;
  };

  activeCampFetchFields = async data => {
    const response = this.activeCampFields.length > 0 ? { data: this.activeCampFields } : await activeCamapaignFeilds(data);
    try {
      runInAction(() => {
        this.activeCampFields = response?.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.activeCampFields;
  };
}

decorate(ObservableIntegrationStore, {
  contact: observable,
  activeCampFilter: observable,
  activeCampFields: observable,
  invoiceLink: observable,
  hubspotFieldList: observable,
  zohoFieldList: observable,
  importContactFormProps: observable
});

export default new ObservableIntegrationStore();
