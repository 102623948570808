import { action, decorate, observable, runInAction, toJS } from "mobx";
import moment from "moment";
import { getAllContacts } from "service/contactService";
import { dailySms, monthlySms, onBoardingChecklist } from "service/dashboardService";
import { getCostOfCampaign, isAnalyticsDataAvailable } from "service/gatewayService";

class ObservableDashboardtStore {
  response = undefined;
  checklist = undefined;
  showAnalytics = false;

  isUserView = undefined;

  setUserView = view => {
    runInAction(() => {
      this.isUserView = view;
    });
  };

  //statics for cards (SMS & MMS)
  statics = {
    sms: {},
    mms: {}
  };

  graphData = {
    sms: {
      time: [],
      delivered: [],
      received: [],
      spent: [],
      sent: [],
      inbound: [],
      outbound: []
    },
    mms: {
      time: [],
      delivered: [],
      received: [],
      spent: [],
      sent: [],
      inbound: [],
      outbound: []
    }
  };

  // reseting all values
  resetValues = () => {
    this.graphData = {
      sms: {
        time: [],
        delivered: [],
        received: [],
        sent: [],
        inbound: [],
        outbound: []
      },
      mms: {
        time: [],
        delivered: [],
        received: [],
        sent: [],
        inbound: [],
        outbound: []
      }
    };
    // for cards
    this.statics = {
      sms: {},
      mms: {}
    };
  };

  isAnalyticsDataAvailable = async () => {
    const response = await isAnalyticsDataAvailable();
    runInAction(() => {
      try {
        if (response.status && response?.data) {
          this.showAnalytics = response?.data?.showAnalytics;
        } else {
          this.showAnalytics = false;
        }
      } catch (error) {
        this.showAnalytics = false;
      }
    });
    return this.showAnalytics;
  };

  dailySms = async params => {
    const dailySmsResponse = await dailySms(params);
    this.response = dailySmsResponse?.data;
    try {
      runInAction(() => {
        this.resetValues();
        if (dailySmsResponse.data.smsStats.length > 0) {
          dailySmsResponse.data.smsStats.map(({ fields: { totalSMS, totalSpent, totalSMSReceived, totalSMSReject, totalSMSSent, totalSMSBlocked, totalSMSDelivered, ..._ }, time }) => {
            // for card

            this.statics.sms.spent = (this.statics.sms.spent ?? 0) + totalSpent || 0;
            if (this.isUserView) {
              this.statics.sms.inbound = (this.statics.sms.inbound ?? 0) + totalSMSReceived || 0;
              this.statics.sms.outbound =
                (this.statics.sms.outbound ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;
              this.statics.sms.optout = (this.statics.sms.optout ?? 0) + totalSMSBlocked || 0;
            } else {
              this.statics.sms.delivered = (this.statics.sms.delivered ?? 0) + totalSMSDelivered || 0;
              this.statics.sms.sent = (this.statics.sms.sent ?? 0) + totalSMSSent || 0;
              this.statics.sms.received = (this.statics.sms.received ?? 0) + totalSMSReceived || 0;
              this.statics.sms.failed = (this.statics.sms.failed ?? 0) + _?.totalSMSFailed || 0;
              this.statics.sms.blocked = (this.statics.sms.blocked ?? 0) + totalSMSBlocked || 0;
              this.statics.sms.expired = (this.statics.sms.expired ?? 0) + _?.totalSMSExpired || 0;
              this.statics.sms.queued = (this.statics.sms.queued ?? 0) + _?.totalSMSQueued || 0;
              this.statics.sms.total =
                (this.statics.sms.total ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;

              const { totalSMSFailed, totalSMSExpired, ...otherFields } = _;

              this.statics.sms.others = (this.statics.sms.others ?? 0) + Object.values(otherFields).reduce((acc, value) => acc + value, 0) || 0;
            }
            // for graph
            this.graphData.sms.time.push(moment(time).format("DD MMM"));

            if (!this.isUserView) {
              this.graphData.sms.delivered.push(totalSMSDelivered || 0);
              this.graphData.sms.sent.push(totalSMSSent || 0);
              this.graphData.sms.received.push(totalSMSReceived || 0);
              Object.entries(_).map(([key, value]) => {
                key = key.replace("totalSMS", "");
                if (key) {
                  if (!this.graphData.sms?.[key]) this.graphData.sms[key] = [];

                  this.graphData.sms[key].push(value);
                }
              });
              delete this.graphData.sms.inbound;
              delete this.graphData.sms.outbound;
              delete this.graphData.sms.optout;
            } else {
              this.graphData.sms.inbound.push(totalSMSReceived || 0);
              // this.graphData.sms.outbound.push(totalSMSDelivered + totalSMSSent || 0);
              this.graphData.sms.outbound.push(
                Object.values({
                  totalSMSReject,
                  totalSMSDelivered,
                  totalSMSSent,
                  ..._
                }).reduce((acc, value) => acc + value, 0) || 0
              );
              // this.graphData.sms.optout.push(totalSMSBlocked || 0);
              delete this.graphData.sms.delivered;
              delete this.graphData.sms.received;
              delete this.graphData.sms.sent;
            }
          });
        }
        if (dailySmsResponse.data.mmsStats.length > 0) {
          dailySmsResponse.data.mmsStats.map(({ fields: { totalSMS, totalSpent, totalSMSSent, totalSMSReject, totalSMSReceived, totalSMSBlocked, totalSMSDelivered, ..._ }, time }) => {
            // for card
            this.statics.mms.spent = (this.statics.mms.spent ?? 0) + totalSpent || 0;
            if (this.isUserView) {
              this.statics.mms.inbound = (this.statics.mms.inbound ?? 0) + totalSMSReceived || 0;
              this.statics.mms.outbound =
                (this.statics.mms.outbound ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;
              this.statics.mms.optout = (this.statics.mms.optout ?? 0) + totalSMSBlocked || 0;
            } else {
              this.statics.mms.delivered = (this.statics.mms.delivered ?? 0) + totalSMSDelivered || 0;
              this.statics.mms.received = (this.statics.mms.received ?? 0) + totalSMSReceived || 0;
              this.statics.mms.sent = (this.statics.mms.sent ?? 0) + totalSMSSent || 0;
              this.statics.mms.failed = (this.statics.mms.failed ?? 0) + _?.totalSMSFailed || 0;
              this.statics.mms.blocked = (this.statics.mms.blocked ?? 0) + totalSMSBlocked || 0;
              this.statics.mms.expired = (this.statics.mms.expired ?? 0) + _?.totalSMSExpired || 0;
              this.statics.mms.queued = (this.statics.mms.queued ?? 0) + _?.totalSMSQueued || 0;
              this.statics.mms.total =
                (this.statics.mms.total ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;

              const { totalSMSFailed, totalSMSExpired, ...otherFields } = _;

              this.statics.mms.others = (this.statics.mms.others ?? 0) + Object.values(otherFields).reduce((acc, value) => acc + value, 0) || 0;
            }

            // for graph
            this.graphData.mms.time.push(moment(time).format("DD MMM"));
            if (!this.isUserView) {
              this.graphData.mms.delivered.push(totalSMSDelivered || 0);
              this.graphData.mms.received.push(totalSMSReceived || 0);
              this.graphData.mms.sent.push(totalSMSSent || 0);
              Object.entries(_).map(([key, value]) => {
                key = key.replace("totalSMS", "");
                if (key) {
                  if (!this.graphData.mms?.[key]) this.graphData.mms[key] = [];

                  this.graphData.mms[key].push(value);
                }
              });
              delete this.graphData.mms.inbound;
              delete this.graphData.mms.optout;
              delete this.graphData.mms.outbound;
            } else {
              this.graphData.mms.inbound.push(totalSMSReceived || 0);
              this.graphData.mms.outbound.push(
                Object.values({
                  totalSMSReject,
                  totalSMSDelivered,
                  totalSMSSent,
                  ..._
                }).reduce((acc, value) => acc + value, 0) || 0
              );
              // this.graphData.mms.optout.push(totalSMSBlocked || 0);
              delete this.graphData.mms.delivered;
              delete this.graphData.mms.received;
              delete this.graphData.mms.sent;
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
      dailySmsResponse = {};
    }
  };

  monthlySms = async params => {
    let monthlySmsResponse = await monthlySms(params);
    this.response = monthlySmsResponse?.data;
    try {
      runInAction(() => {
        this.resetValues();
        if (monthlySmsResponse.data.smsStats.length > 0) {
          monthlySmsResponse.data.smsStats.map(({ fields: { totalSMS, totalSpent, totalSMSReceived, totalSMSSent, totalSMSReject, totalSMSBlocked, totalSMSDelivered, ..._ }, time }) => {
            // for card
            this.statics.sms.spent = (this.statics.sms.spent ?? 0) + totalSpent || 0;
            if (this.isUserView) {
              this.statics.sms.inbound = (this.statics.sms.inbound ?? 0) + totalSMSReceived || 0;
              this.statics.sms.outbound =
                (this.statics.sms.outbound ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;
              this.statics.sms.optout = (this.statics.sms.optout ?? 0) + totalSMSBlocked || 0;
            } else {
              this.statics.sms.delivered = (this.statics.sms.delivered ?? 0) + totalSMSDelivered || 0;
              this.statics.sms.sent = (this.statics.sms.sent ?? 0) + totalSMSSent || 0;
              this.statics.sms.received = (this.statics.sms.received ?? 0) + totalSMSReceived || 0;
              this.statics.sms.failed = (this.statics.sms.failed ?? 0) + _?.totalSMSFailed || 0;
              this.statics.sms.blocked = (this.statics.sms.blocked ?? 0) + totalSMSBlocked || 0;
              this.statics.sms.expired = (this.statics.sms.expired ?? 0) + _?.totalSMSExpired || 0;
              this.statics.sms.queued = (this.statics.sms.queued ?? 0) + _?.totalSMSQueued || 0;
              this.statics.sms.total =
                (this.statics.sms.total ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;

              const { totalSMSFailed, totalSMSExpired, ...otherFields } = _;

              this.statics.sms.others = (this.statics.sms.others ?? 0) + Object.values(otherFields).reduce((acc, value) => acc + value, 0) || 0;
            }

            // for graph
            this.graphData.sms.time.push(moment(time).format("MMM"));
            if (!this.isUserView) {
              this.graphData.sms.delivered.push(totalSMSDelivered || 0);
              this.graphData.sms.sent.push(totalSMSSent || 0);
              this.graphData.sms.received.push(totalSMSReceived || 0);
              Object.entries(_).map(([key, value]) => {
                key = key.replace("totalSMS", "");
                if (key) {
                  if (!this.graphData.sms?.[key]) this.graphData.sms[key] = [];

                  this.graphData.sms[key].push(value);
                }
              });
              delete this.graphData.sms.outbound;
              delete this.graphData.sms.inbound;
              delete this.graphData.sms.optout;
            } else {
              this.graphData.sms.inbound.push(totalSMSReceived || 0);
              this.graphData.sms.outbound.push(
                Object.values({
                  totalSMSReject,
                  totalSMSDelivered,
                  totalSMSSent,
                  ..._
                }).reduce((acc, value) => acc + value, 0) || 0
              );
              // this.graphData.sms.optout.push(totalSMSBlocked || 0);
              delete this.graphData.sms.delivered;
              delete this.graphData.sms.received;
              delete this.graphData.sms.sent;
            }
          });
        }
        if (monthlySmsResponse.data.mmsStats.length > 0) {
          monthlySmsResponse.data.mmsStats.map(({ fields: { totalSMS, totalSpent, totalSMSSent, totalSMSReceived, totalSMSReject, totalSMSBlocked, totalSMSDelivered, ..._ }, time }) => {
            // for card
            this.statics.mms.spent = (this.statics.mms.spent ?? 0) + totalSpent || 0;
            if (this.isUserView) {
              this.statics.mms.inbound = (this.statics.mms.inbound ?? 0) + totalSMSReceived || 0;
              this.statics.mms.outbound =
                (this.statics.mms.outbound ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;
              this.statics.mms.optout = (this.statics.mms.optout ?? 0) + totalSMSBlocked || 0;
            } else {
              this.statics.mms.delivered = (this.statics.mms.delivered ?? 0) + totalSMSDelivered || 0;
              this.statics.mms.received = (this.statics.mms.received ?? 0) + totalSMSReceived || 0;
              this.statics.mms.sent = (this.statics.mms.sent ?? 0) + totalSMSSent || 0;
              this.statics.mms.failed = (this.statics.mms.failed ?? 0) + _?.totalSMSFailed || 0;
              this.statics.mms.blocked = (this.statics.mms.blocked ?? 0) + totalSMSBlocked || 0;
              this.statics.mms.expired = (this.statics.mms.expired ?? 0) + _?.totalSMSExpired || 0;
              this.statics.mms.queued = (this.statics.mms.queued ?? 0) + _?.totalSMSQueued || 0;
              this.statics.mms.total =
                (this.statics.mms.total ?? 0) +
                  Object.values({
                    totalSMSReject,
                    totalSMSDelivered,
                    totalSMSSent,
                    ..._
                  }).reduce((acc, value) => acc + value, 0) || 0;

              const { totalSMSFailed, totalSMSExpired, ...otherFields } = _;

              this.statics.mms.others = (this.statics.mms.others ?? 0) + Object.values(otherFields).reduce((acc, value) => acc + value, 0) || 0;
            }

            // for graph
            this.graphData.mms.time.push(moment(time).format("MMM"));
            if (!this.isUserView) {
              this.graphData.mms.delivered.push(totalSMSDelivered || 0);
              this.graphData.mms.received.push(totalSMSReceived || 0);
              this.graphData.mms.sent.push(totalSMSSent || 0);
              Object.entries(_).map(([key, value]) => {
                key = key.replace("totalSMS", "");
                if (key) {
                  if (!this.graphData.mms?.[key]) this.graphData.mms[key] = [];

                  this.graphData.mms[key].push(value);
                }
              });
              delete this.graphData.mms.inbound;
              delete this.graphData.mms.outbound;
              delete this.graphData.mms.optout;
            } else {
              this.graphData.mms.inbound.push(totalSMSReceived || 0);
              this.graphData.mms.outbound.push(
                Object.values({
                  totalSMSReject,
                  totalSMSDelivered,
                  totalSMSSent,
                  ..._
                }).reduce((acc, value) => acc + value, 0) || 0
              );
              // this.graphData.mms.optout.push(totalSMSBlocked || 0);
              delete this.graphData.mms.delivered;
              delete this.graphData.mms.received;
              delete this.graphData.mms.sent;
            }
          });
        }
      });
    } catch (error) {
      monthlySmsResponse = error;
    }
  };

  totalSms = () => {
    // let totalSmsResponse = await totalSms();
    // const optOutParam = {
    //   page: 1,
    //   sortField: "updatedAt",
    //   sortOrder: "descend",
    //   isBlocked: true,
    //   isBlockedByUser: true
    // };
    // const smsRate = await getCostOfCampaign({ totalSms: 1 });
    // const mmsRate = await getCostOfCampaign({ totalMMS: 1 });
    // const optOut = await getAllContacts(optOutParam);
    // try {
    //   runInAction(() => {
    //     this.statics.sms.rate = smsRate.data.rate || 0;
    //     this.statics.mms.rate = mmsRate.data.rate || 0;
    //     this.statics.optOut = optOut.total || 0;
    //   });
    // } catch (error) {
    //   this.totalSmsResponse = error;
    // }
    return this.statics;
  };

  getChecklistStats = async () => {
    const checklistData = await onBoardingChecklist();
    try {
      runInAction(() => {
        // this.checklist = Object.keys(checklistData.data).map(key => {
        //   return { id: key, status: checklistData.data[key] };
        // });
        this.checklist = checklistData.data;
      });
    } catch (error) {
      this.checklist = error;
    }
  };
}

decorate(ObservableDashboardtStore, {
  response: observable,
  isUserView: observable,
  dailySms: observable,
  monthlySms: observable,
  statics: observable,
  graphData: observable,
  checklist: observable,
  showAnalytics: observable
});
export default new ObservableDashboardtStore();
