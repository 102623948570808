import React, { useState, useCallback, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Input, Spin, List, Avatar, Typography, Empty } from "antd";
import { TeamOutlined, UserOutlined, PhoneOutlined, InboxOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { checkNumberwithRegex } from "constant/contacts";
import { debounce } from "lodash";
import { debounceTime } from "constant/constant";
import AppButton from "components/AppButton/AppButton";
import { getFormatedContact } from "constant/messageConstants";

const { Text } = Typography;

const GroupSearch = props => {
  const { contactStore, initialSearchTerm = "", resetSearch, onResetComplete, autoFocus } = props;

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchError, setSearchError] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialSearchTerm) {
      handleSearchChange(initialSearchTerm);
    }
  }, [initialSearchTerm]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [autoFocus]);

  useEffect(() => {
    if (resetSearch) {
      setSearchTerm("");
      setSearchResults([]);
      onResetComplete();
    }
  }, [resetSearch, onResetComplete]);

  const handleSearchChange = useCallback(
    debounce(async (value, page = 1) => {
      if (value) {
        setSearchError("");
        setIsSearching(page === 1);
        setIsLoadingMore(page > 1);

        try {
          let searchValue = value;

          if (!isNaN(value)) {
            const { status, validNumber } = await checkNumberwithRegex([value]);
            if (!status) {
              setSearchResults([]);
              setIsSearching(false);
              setIsLoadingMore(false);
              return;
            }
            const contact = getFormatedContact(validNumber[0], false);
            searchValue = contact.replace(/^(\+?61(0)?)|[()\s+]/g, "") || contact;
          }

          const response = await contactStore.findContactGroup(searchValue, page);
          const results = response?.items;

          if (results && results.length > 0) {
            if (page === 1) {
              setSearchResults(results);
            } else {
              setSearchResults(prevResults => [...prevResults, ...results]);
            }
            setHasMore(response.hasNextPage);
          } else {
            if (page === 1) {
              setSearchResults([]);
            }
            setHasMore(false);
          }
        } catch (error) {
          setSearchError("An error occurred while searching. Please try again.");
        } finally {
          setIsSearching(false);
          setIsLoadingMore(false);
        }
      } else {
        setSearchResults([]);
        setSearchError("");
      }
    }, debounceTime),
    [contactStore]
  );

  const loadMoreSearchResults = useCallback(() => {
    if (!isLoadingMore && hasMore) {
      const nextPage = contactStore.current + 1;
      handleSearchChange(searchTerm, nextPage);
    }
  }, [isLoadingMore, hasMore, contactStore?.current, searchTerm, handleSearchChange]);

  const getFullName = fields => {
    // const firstName = fields.find(f => f.name === "first name")?.value || "";
    // const lastName = fields.find(f => f.name === "last name")?.value || "";
    const firstName = fields?.[0]?.value || "";
    const lastName = fields?.[1]?.value || "";
    return `${firstName} ${lastName}`?.trim() || "";
  };

  const renderSearchResults = () => (
    <div id="scrollableDiv" style={{ height: 400, overflow: "auto" }}>
      <InfiniteScroll
        dataLength={searchResults.length}
        next={loadMoreSearchResults}
        hasMore={hasMore}
        loader={
          <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <Spin tip="Loading more..." className="text-info" />
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <List
          itemLayout="horizontal"
          dataSource={searchResults}
          renderItem={result => (
            <List.Item key={result._id} className="group-search-item">
              <div className="group-search-content">
                <Avatar icon={<TeamOutlined />} className="group-avatar" />
                <div className="group-info">
                  <Text strong className="group-name">
                    {result?.group?.name || "Unnamed Group"}
                  </Text>
                  <div className="contact-info">
                    <Text>
                      <UserOutlined /> <span>{getFullName(result?.fields)}</span>
                    </Text>
                    <Text>
                      <PhoneOutlined /> <span>{result?.number}</span>
                    </Text>
                  </div>
                </div>
              </div>
              <Link to={`/group/${result?.group?._id}?number=${result?.number}`} className="view-group-link">
                <AppButton label="View Group" size="small" light withoutBg />
              </Link>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );

  return (
    <div className="group-search-container">
      {!initialSearchTerm && (
        <Input
          placeholder="Enter contact number or contact name"
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            handleSearchChange(e.target.value);
          }}
          prefix={<i className="bx bx-search align-middle me-2" />}
          className="mb-3"
          ref={inputRef}
        />
      )}
      {searchError && <p className="mt-2 text-danger">{searchError}</p>}
      {isSearching ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
          <Spin className="text-info" tip="Searching..." size="large" />
        </div>
      ) : (
        searchResults.length > 0 && renderSearchResults()
      )}
      {searchResults.length === 0 && searchTerm && !searchError && !isSearching && (
        <div style={{ textAlign: "center", margin: "40px 0" }}>
          <Empty image={<InboxOutlined style={{ fontSize: 60 }} />} imageStyle={{ height: 60, marginBottom: 10 }} description={<span style={{ color: "#666" }}>No contacts found</span>} />
        </div>
      )}
    </div>
  );
};

export default inject(stores => ({
  contactStore: stores.store.contactStore
}))(observer(GroupSearch));
