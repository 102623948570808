import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Timeline, Typography, Spin, Row, Col, Card, Empty, Tooltip, Icon } from "antd";
import AppDrawer from "components/AppDrawer/AppDrawer";
import AppButton from "components/AppButton/AppButton";
import { dateFormat, timeFormat } from "constant/constant";
import FilterBar from "components/History/FilterBar";
import GroupSearch from "components/FormItems/GroupSearch";
const { Text } = Typography;

const ContactLogDrawer = props => {
  const { messageStore, number, handleClose, visible, isMms = false, modalStore, contactStore, groupStore } = props;
  const { getContactHistory, search, setSearch, current, total, setPage, contactHistory } = messageStore;

  const { getContact } = contactStore;

  // group contact history by date
  const groupByDate = contactHistory.reduce((r, a) => {
    r[moment(a.createdAt).format("YYYY-MM-DD")] = [...(r[moment(a.createdAt).format("YYYY-MM-DD")] || []), a];
    return r;
  }, {});

  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [status, setStatus] = useState("");
  const [messageType, setMessageType] = useState("sms");
  const [messageList, setMessageList] = useState({});
  const [contactDetails, setContactDetails] = useState(null);
  const [initalLoad, setInitialLoad] = useState(true);

  const [showGroupSearch, setShowGroupSearch] = useState(false);
  useEffect(() => {
    setMessageList({ ...messageList, ...groupByDate });
  }, [contactHistory]);

  useEffect(() => {
    setLoading(true);
    if (visible) {
      getContact(number).then(res => {
        if (res.status) {
          setContactDetails(res.data);
        }
      });
      getContactHistory({ receiver: number }, messageType === "mms").finally(() => {
        setLoading(false);
        setInitialLoad(false);
      });
    }
  }, [search, current, number, messageType]);

  useEffect(() => {
    setSearch({
      status: status == "all" ? undefined : status ? status : undefined,
      from: dateRange ? dateRange[0] : undefined,
      to: dateRange ? dateRange[1] : undefined
    });
  }, [status, dateRange]);

  useEffect(() => {
    setMessageType(isMms ? "mms" : "sms");
  }, [isMms]);

  const smsMenu = [
    { label: "SMS", value: "sms" },
    { label: "MMS", value: "mms" }
  ];

  function quickModal() {
    messageStore.setCurrentMessage("");
    messageStore.setPhoneSubject("");
    messageStore.phoneFrameImageUrl = undefined;
    messageStore.phoneFrameImageFileUrl = undefined;
    modalStore.toggleModal("quickMessage", true);
  }

  function runQuickMessage() {
    modalStore.setQuickSMSData({ receiver: number });
    quickModal();
    handleClose();
  }

  const isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const changeDates = (dates, dateStrings) => {
    setDateRange(dateStrings);
    setMessageList({});
    setPage(1);
  };

  const changeStatus = status => {
    setMessageList({});
    setPage(1);
    setStatus(status);
  };

  const changeMessageType = type => {
    setMessageList({});
    setMessageType(type);
    setPage(1);
  };

  return (
    <>
      <AppDrawer
        label={
          <Row gutter={[16, 16]} type="flex" align="middle">
            <Col span={17}>
              <Typography.Text className="fs-6">
                Contact No.{" "}
                <Typography.Text className="text-info-color">
                  +61{number} {contactDetails && contactDetails.isBlocked ? "(Opt-out)" : "(Opt-in)"}
                  {contactDetails && contactDetails.isBlockedByUser && (
                    <Tooltip title="Opt-out by Customer">
                      <i className="fas fa-ban ms-2 text-danger-color" />
                    </Tooltip>
                  )}
                </Typography.Text>
              </Typography.Text>
            </Col>
            <Col span={14} className="text-end">
              <AppButton onClick={() => runQuickMessage()} light label="Quick Message" size="default" prefixIcon={<Icon type="message" className="me-2" />} />

              <AppButton onClick={() => setShowGroupSearch(true)} light label="Find Contact" size="default" className="ms-2" prefixIcon={<Icon type="search" className="me-2 align-middle" />} />
            </Col>
          </Row>
        }
        visible={visible}
        onClose={() => {
          handleClose();
          setMessageList({});
          setContactDetails(null);
          setInitialLoad(true);
          setShowGroupSearch(false);
        }}
        destroyOnClose
        width={600}
        bodyStyle={{
          height: "calc(100vh - 70px)",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}
      >
        {showGroupSearch && (
          <Row gutter={[16, 16]} type="flex" align="middle">
            <Col span={24}>
              <Text strong className="text-info-color fs-5 mb-3 d-block mt-4">
                Groups containing this contact:
              </Text>
              <GroupSearch initialSearchTerm={number} />
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]} type="flex" align="middle">
          <Col md={24}>
            <FilterBar fullSize changeDates={changeDates} changeStatus={changeStatus} changeMessageType={changeMessageType} messageType={messageType} status={status} dates={dateRange} />
          </Col>
        </Row>

        <Row gutter={[16, 16]} type="flex" align="middle">
          <Col span={24}>
            {initalLoad ? (
              <div className="text-center">
                <Spin />
              </div>
            ) : (
              <Timeline
                pendingDot={loading ? <Spin className="fs-6" /> : <i className="fas fa-plus fs-6" />}
                pending={
                  total > current * 50 ? (
                    <AppButton label={loading ? "Loading..." : "Load More"} size="small" light className="ms-2" disabled={loading} withoutBg onClick={() => setPage(current + 1)} />
                  ) : null
                }
              >
                {!isEmpty(messageList)
                  ? Object.keys(messageList).map((date, index) => (
                      <Timeline.Item key={index} label={date}>
                        <Typography.Text type="secondary" strong className="mb-2">
                          {moment(messageList[date][0].createdAt).format(dateFormat)}
                        </Typography.Text>
                        {messageList[date].map((item, index) => (
                          <Card
                            key={index}
                            className="rounded mb-2"
                            bodyStyle={{
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <Typography.Text className="align-self-end" type="secondary">
                              {moment(item.createdAt).format(timeFormat)}
                            </Typography.Text>
                            <Typography.Text type="secondary" strong>
                              {item.status.toUpperCase()}
                            </Typography.Text>
                            <Typography.Text className="d-block">{item.status === "received" ? `Recevier: ${item.receiver}` : `Sender: ${item.sender}`}</Typography.Text>
                            <Typography.Paragraph
                              className="text-break"
                              ellipsis={{
                                rows: 3,
                                expandable: true,
                                symbol: "more"
                              }}
                              style={{ whiteSpace: "break-spaces" }}
                              copyable
                            >
                              {item.message}
                            </Typography.Paragraph>
                            {messageType === "mms" && item.media && (
                              <div className="d-flex flex-wrap">
                                {item.media.map((item, index) => (
                                  <div key={index} className="d-flex flex-column align-items-center me-2">
                                    <a href={item.url} target="_blank" rel="noreferrer">
                                      <i className="fas fa-file-image fa-2x text-info-color"></i>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            )}
                          </Card>
                        ))}
                      </Timeline.Item>
                    ))
                  : !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </Timeline>
            )}
          </Col>
        </Row>
      </AppDrawer>
    </>
  );
};

export default inject(stores => ({
  messageStore: stores.store.messageStore,
  modalStore: stores.store.modalStore,
  contactStore: stores.store.contactStore,
  groupStore: stores.store.groupStore,
  contactStore: stores.store.contactStore
}))(observer(ContactLogDrawer));
