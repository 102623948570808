import { get, post, put, deleteRequest } from "utils/api";

// Delete or empty groups

export const deleteOrEmptyGroups = async (groups, action) => {
  const response = await post("/group/delete-empty-group", { groups, action }, true, { "Content-Type": "application/json" });
  return response.data;
};
// get all groups
export const getAllGroups = async params => {
  const groupListResponse = await get(`/group`, params);
  return groupListResponse.data.data;
};

// add new group
export const addGroup = async (groupData = {}) => {
  const addResponse = await post(`/group`, groupData, true, {
    "Content-Type": `multipart/form-data`
  });
  return addResponse.data;
};

// add bulk contacts to group
export const addContacts = async (groupData = {}, groupId) => {
  const updateResponse = await put(`/mobile/group/${groupId}`, groupData, true, {
    "Content-Type": `multipart/form-data`
  });
  return updateResponse.data;
};

// update group details
export const updateGroup = async (groupData = {}, groupId) => {
  const updateResponse = await put(`/group/${groupId}`, groupData);
  return updateResponse.data;
};

// delete group
export const deleteGroup = async groupId => {
  const deleteResponse = await deleteRequest(`/group/${groupId}`);
  return deleteResponse.data;
};

// get group data by id
export const getGroupById = async groupId => {
  const groupResponse = await get(`/mobile/group/${groupId}`);
  return groupResponse.data;
};

// get group contacts by id
export const getGroupContactsById = async (params, groupId) => {
  const groupContactsResponse = await get(`/group/data/${groupId}`, params);
  return groupContactsResponse.data.data;
};

// get group fields by id
export const getGroupFields = async groupId => {
  const groupFields = await get(`/group/fields/${groupId}`);
  return groupFields.data;
};

// create group with contact ids
export const addGroupWithContact = async (groupData = {}) => {
  const addResponse = await post(`/group/groupWithcontact`, groupData);
  return addResponse.data;
};

// move contacts to another group
export const moveContactsToGroup = async (contactIds = {}, groupId) => {
  const addResponse = await put(`/group/moveContact/${groupId}`, contactIds);
  return addResponse.data;
};

// Add Single Contact
export const addSingleContact = async (data, groupId) => {
  const addResponse = await put(`/group/quickcontact/${groupId}`, data);
  return addResponse.data;
};

// Update sinlge Contact
export const updateSingleContact = async (data, groupId, contactId) => {
  const updateResponse = await put(`/group/updatequickcontact/${groupId}/${contactId}`, data);
  return updateResponse.data;
};

// Delete Single Contact
export const deleteSingleContact = async contactId => {
  const deleteResponse = await deleteRequest(`/group/deletesinglecontact/${contactId}`);
  return deleteResponse.data;
};

// Delete Multiple Contact
export const deleteMultipleContact = async data => {
  const deleteResponse = await post(`/group/deletemulticontact`, data);
  return deleteResponse.data;
};

// Get Single Contact
export const getSingleContact = async contactId => {
  const getResponse = await get(`/contact/contactdetail/${contactId}`);
  return getResponse.data.data;
};

// praktika  role group add
export const addPraktikaGroup = async data => {
  const addResponse = await post("/group/praktika", data, true, {
    "Content-Type": `multipart/form-data`
  });
  return addResponse.data;
};

// export group contacts
export const getExportGroupContacts = async (id, params) => {
  const repsonse = await get(`/group/getGroupedContact/${id}`, params);
  return repsonse.data;
};
